	@charset 'utf-8';

	@import 'settings';
	@import 'foundation';
	@import 'motion-ui';
	@import 'main'; //magnific-popup

	@include foundation-global-styles;
	// @include foundation-grid;
	// @include foundation-flex-grid;
	//
	@include foundation-xy-grid-classes;
	@include foundation-typography;
	@include foundation-button;
	@include foundation-forms;
	// @include foundation-range-input;
	@include foundation-accordion;
	@include foundation-accordion-menu;
	@include foundation-badge;
	@include foundation-breadcrumbs;
	@include foundation-button-group;
	@include foundation-callout;
	@include foundation-card;
	@include foundation-close-button;
	@include foundation-menu;
	@include foundation-menu-icon;
	@include foundation-drilldown-menu;
	@include foundation-dropdown;
	@include foundation-dropdown-menu;
	@include foundation-responsive-embed;
	@include foundation-label;
	@include foundation-media-object;
	@include foundation-off-canvas;
	@include foundation-orbit;
	@include foundation-pagination;
	@include foundation-progress-bar;
	@include foundation-slider;
	@include foundation-sticky;
	@include foundation-reveal;
	@include foundation-switch;
	@include foundation-table;
	@include foundation-tabs;
	@include foundation-thumbnail;
	@include foundation-title-bar;
	@include foundation-tooltip;
	@include foundation-top-bar;
	@include foundation-visibility-classes;
	// @include foundation-float-classes;
	@include foundation-flex-classes;

	// @include foundation-prototype-classes;

	@include motion-ui-transitions;
	@include motion-ui-animations;

	@font-face {
		font-family: 'planet_estyleregular';
		src: url('../fonts/plane___-webfont.eot');
		src: url('../fonts/plane___-webfont.eot?#iefix') format('embedded-opentype'),
			 url('../fonts/plane___-webfont.woff') format('woff'),
			 url('../fonts/plane___-webfont.ttf') format('truetype'),
			 url('../fonts/plane___-webfont.svg#planet_estyleregular') format('svg');
		font-weight: normal;
		font-style: normal;
	}

	.palmedia {
		font-family: "planet_estyleregular";
		font-size: 110%;
		color: $white;
		&:hover {
			color: $medium-gray;
		}
	}


	body {
		background: $white;
		// background-image: url('../img/bg.jpg'); /* fallback */
		background: linear-gradient(to bottom, transparent 50%, white), url('../img/bg.jpg') no-repeat;
		background-attachment: fixed;
		background-size: 100% auto;
	}

	.logo-shadow {
		-webkit-filter: drop-shadow( 0px 0px 2px rgba(255,255,255,.4) );
		filter: drop-shadow( 0px 0px 2px rgba(255,255,255,.4) );
	}

	.off-canvas {
		.menu {
			color: $white;
			a {
				color: $white;
			}
		}
	}

	.in-page-menu {
		.item {
			a {
				font-size: 1.25rem;
			}
		}
	}

	.header {
		position: relative;
		top: rem-calc(10);
		height: rem-calc(125);
		@include breakpoint(large) {
			height: rem-calc(300);
		}
		.hamburger-menu {
			margin-top: rem-calc(10);
			@include hamburger($primary-color, $black, 30px, 26px, 5px, 3);
		}

		.logo img {
			// position: relative;
			@include breakpoint(large) {
				width: rem-calc(350);
			}
		}
		.header-part {
			height: rem-calc(80);
			z-index: 15;
			.top-bar {
				margin-top: rem-calc(15);
				// margin-top: rem-calc(110);
				background: rgba($primary-color, 0.8);
				border-radius: $menu-radius;
				-webkit-border-radius: $menu-radius;
				-moz-border-radius: $menu-radius;
				padding: 0;
				font-size: rem-calc(15);
				.menu {
					margin: 0 auto;
					a {
						margin: rem-calc(5) rem-calc(2);
						color: $white;
					}
					.submenu {
						padding: rem-calc(5) rem-calc(0);
						$submenu-radius: $menu-radius/2;
						-webkit-border-bottom-right-radius: $submenu-radius;
						-webkit-border-bottom-left-radius: $submenu-radius;
						-moz-border-radius-bottomright: $submenu-radius;
						-moz-border-radius-bottomleft: $submenu-radius;
						border-bottom-right-radius: $submenu-radius;
						border-bottom-left-radius: $submenu-radius;
						z-index: 99;
						a {
							margin: rem-calc(0) rem-calc(2);
						}
					}
				}
			}
		}
		.tools-menu {
			position: relative;
			.search {
				position: absolute;
				right: 35px;
				top:0;
				form {
					margin:0;
					padding:0;
				}
				width: 300px;
				display: inline-block;
				.search-field {
					display: inline-block;
					width: 0;
					height: 32px;
					padding: 0;
					border-radius: 50px;
					border: none;
					transition: all 0.5s ease;
					$searchfield-radius: $menu-radius;
					border-radius: $searchfield-radius;
					-webkit-border-radius: $searchfield-radius;
					-moz-border-radius: $searchfield-radius;
				}

				.expand-search {
					width: 75%;	
					height: 32px;
					max-width: calc(90%);
					padding: 0 1rem;
					transition: all 0.5s ease;
				}

				.search-button {
					position: absolute;
					right:0;
					top: 0;
					height: 32px;
					width: 32px;
					border: 0;
					background-color: $primary-color;
					$searchfield-radius: $menu-radius;
					border-radius: $searchfield-radius;
					-webkit-border-radius: $searchfield-radius;
					-moz-border-radius: $searchfield-radius;
					.fi-magnifying-glass:before {
						font-size: 20px;
						color: $white;
					}
				}
			}
			.social-button {
				position: absolute;
				display: inline-block;
				right: 0;
				top:0;
				height: 32px;
				width: 32px;	
				img {
					height: 32px;
					width: 32px;			
				}
			}	
		}


	}

	.footer {
		background-color: $primary-color;
		position: relative;
		margin-top: 75px;
		color: $white;
		font-size: rem-calc(14);
		height: rem-calc(100);
		padding: rem-calc(35) 0 rem-calc(20) 0;
		position: relative;
		&:before {
			content: url('../img/logo-gelselaar.svg');
			position: absolute;
			width: 50px;
			top: -49px;
			@include breakpoint(small) {
				transform: scale(.8);
				top: -46px;
			}
		}
		p.left {
			@include vertical-center;
			a {
				color: $white;
			}
		}
		p.right {
			@include vertical-center;
			right: rem-calc(20);
		}
	}


	.highlight-item-wrapper {
	  position: relative;
	  a {
	    display: inline-block;
	    letter-spacing: normal;
	    line-height: initial;
	    margin: 0;
	    padding: 0;
	    position: relative;

		h2 {
			position: absolute;
			background-color: rgba($primary-color ,0.8);
			text-align: center;
			color: $white;
			left: 0;
			right: 0;
			bottom: rem-calc(10);
			padding: 0.5rem;
			z-index: 10;
		}
	    img {
	      float: left;
	      overflow: hidden;
	      width: 100%;
		  }
		}
	}

	.main-content {
		background-color: $white;
		$main-content-radius: 10px;
		-webkit-border-top-right-radius: $main-content-radius;
		-webkit-border-top-left-radius: $main-content-radius;
		-moz-border-radius-topright: $main-content-radius;
		-moz-border-radius-topleft: $main-content-radius;
		border-top-right-radius: $main-content-radius;
		border-top-left-radius: $main-content-radius;
		// @include xy-cell();
	}

	.sidebar {
		h2 {
			a {
				color: $body-font-color;
				&:hover {
					color: $primary-color;
				}
			}
		}
	}

	.footer-content {
		background-color: $white;
		// @include xy-cell();
	}

	.calendar-month {
		display: inline-block;
		color: $primary-color;
		padding: 0 0 rem-calc(10) 0;
		font-size: 120%
	}

	.calendar-icon-round {
		$calendar-radius: rem-calc(25);
		$calendar-line-height: rem-calc(50);
		display: block;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		background-color: rgba($primary-color ,0.65);
		border: 1px solid $primary-color;
	    height: $calendar-radius*2;
	    width: $calendar-radius*2;
	    color: $white;
	    font-size: rem-calc(20);
	    text-align: center;
	    line-height: $calendar-line-height;
	    margin-right: rem-calc(8);
	}

	.calender-text {
		margin: rem-calc(13) auto rem-calc(5) auto;
	}

	.news-item {
		border-bottom: 1px solid $light-gray;
		margin: auto auto rem-calc(10);
		position: relative;
		p {
			a {
				font-weight: bold;
				color: $primary-color;

			}
			margin: auto auto rem-calc(10);
		}
		&:last-child {
			border-bottom: 0;
		}
	}

	.bulletin-item {
		border-bottom: 1px solid $light-gray;
		margin: auto auto rem-calc(10);
		position: relative;
		p {
			a {
				font-weight: bold;
				color: $primary-color;

			}
			margin: auto auto rem-calc(10);
		}
		&:last-child {
			border-bottom: 0;
		}
	}

	// NEW calendar
	.calendar-item {
		border-bottom: 1px solid $medium-gray;
		margin: auto auto rem-calc(10);
		position: relative;
		&:after {
			content: '>';
			font-weight: bold;
			color: $primary-color;
			position: absolute;
			right: 0; 
			top: 20%;
		}
		&:last-child {
			border-bottom: 0;
		}
		a {
			display: block;
			color: $black;
			font-size: 110%;
			margin: auto auto rem-calc(10);
			.date {
				color: $primary-color;
				font-size: 90%;
				display: block;
			}
		}
	}


	.photos-more-link {
		font-size: rem-calc(19);
		a {
			color: $primary-color;
		}
	}

	.published_date {
		text-transform: uppercase;
		color: $dark-gray;
		font-size: rem-calc(13);
		margin-bottom: rem-calc(10);
		&:before {
			margin-right: 5px;
		}
	}
	.share {
		color: $dark-gray;
		a {
			color: $dark-gray;
			&:hover {
				color: $primary-color;
			}
			span {
				margin-left: 5px;
				&:before {
					margin-right: 5px;
				}
			}
		}
	}

	.calendar-item-cell
	{
		background-color: smart-scale($primary-color, 75%);
		a {
			display: block;
		}
	}
